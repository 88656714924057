import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Courses from './pages/courses/Courses';
import SingleCourse from './pages/courses/SingleCourse';
import Home from './pages/home/Home';
import Login from './pages/login/login';
import Pricing from './pages/pricing/Pricing';
import Signup from './pages/signup/Signup';

function App() {
  return (
    <BrowserRouter>
      <Navbar></Navbar>
      <Routes>
        <Route element={<Home />} path='/' exact />
        <Route element={<Home />} path='/home' exact />
        <Route element={<Courses />} path='/courses' />
        <Route element={<About />} path='/about' />
        <Route element={<Pricing />} path='/pricing' />
        <Route element={<Contact />} path='/contact' />
        <Route element={<Login />} path='/login' />
        <Route element={<Signup />} path='/signup' />
        <Route element={<SingleCourse />} path='/singlecourse' />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
