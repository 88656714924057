import React from 'react'
import { NavLink } from 'react-router-dom'

const NavBtn = ({ to, text }) => {
    return (
        <div className='w-full'>
            <NavLink to={to} exact className="flex p-5 items-center justify-center  hover:cursor-pointer h-full sm:w-auto border-transparent focus:border-blue-400 hover:border-b-2 hover:border-blue-400 dark:hover:bg-inherit hover:bg-gray-300 group hover:transition duration-200">
                <p className="h-full text-lg group-hover:transition duration-200 group-hover:text-blue-400 group-focus:text-blue-400">
                    {text}
                </p>
            </NavLink>
        </div>
    )
}

export default NavBtn
