import React from 'react';

const Background = ({ children }) => {
    return (

        <div className="bg-gray-200 dark:bg-gray-800 transition-all m-none w-full  h-full overflow-hidden">
            {children}

        </div>
    )
}

export default Background;