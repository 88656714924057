import { Stream } from "@cloudflare/stream-react";
import 'tw-elements'
const Courses = () => {
    return (

        <div className="flex w-full h-screen overflow-hidden bg-gray-200 dark:bg-gray-800 ">
            <div className="flex flex-row w-full">
                {/* <div className="bg-slate-400 w-20 h-full">
                    hello
                </div> */}
                <div className="flex-none  w-48 h-full ">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item ">
                            <h2 className="accordion-header mb-0" id="headingOne">
                                <button
                                    className="flex items-center w-full py-2 px-5 text-base text-gray-400 font-medium border-b border-gray-700 transition
                                    focus:bg-gray-900 focus focus:text-blue-400
                                    "
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    Accordion Item #1
                                </button>
                            </h2>
                            <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body py-4 px-5">
                                    <strong>This is the first item's accordion body.</strong> It is shown by default,
                                    until the collapse plugin adds the appropriate classes that we use to style each
                                    element. These classes control the overall appearance, as well as the showing and
                                    hiding via CSS transitions. You can modify any of this with custom CSS or overriding
                                    our default variables. It's also worth noting that just about any HTML can go within
                                    the <code>.accordion-body</code>, though the transition does limit overflow.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" flex-1  w-full h-full bg-gray-100 dark:bg-gray-700">
                    hello
                    je
                </div>
            </div>
        </div>


        // <div className="flex flex-col md:flex-row md:space-y-1 md:space-x-2 items-center bg-gray-100 dark:bg-gray-600">

        //     <div className="flex-none">

        //         <Stream controls responsive={false} startTime="0h1m27s" src="4488b83e0ce6492fad46b404d7ee497e" />
        //     </div>
        //     <div className="flex-none">
        //         <div>
        //             <h1 className="text-2xl">Learn Anywhere. Anytime.</h1>
        //             <p>lorem*5</p>

        //         </div>
        //         <div className="mt-4">

        //             <a href="#" className=" bg-blue-500 px-6 py-3 text-xl rounded-md">Hell0</a>
        //         </div>
        //     </div>
        // </div >
    )
};

export default Courses;
