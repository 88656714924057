import React from 'react'
import SideBar from '../../components/SideBar'
import Home from '../home/Home'

const SingleCourse = () => {
    return (
        <div className='flex flex-row '>
            <div className='hidden md:contents'>

                <SideBar className="" />
            </div>
            <Home />
        </div>
    )
}

export default SingleCourse
