import { Stream } from "@cloudflare/stream-react";
import Button from "../../components/Button";
import Footer from "../../components/Footer";

const Home = () => {
    return (
        <div className="m-auto  bg-gray-100 dark:bg-gray-700 h-full w-full overflow-hidden text-xl">
            <section className="m-auto py-20 flex flex-col space-y-2 md:flex-row md:space-y-1 md:space-x-2 items-center justify-center w-9/12 overflow-auto">

                <div className="m-auto flex-grow-0  flex items-center justify-center">

                    <div className="space-y-10">
                        <div className="space-y-5 dark:text-gray-50 text-gray-800">

                            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-semibold anti-aliased leading-tight">
                                LEARN <br /> ANYWHERE. <br /> ANYTIME
                            </h1>
                            <p className="dark:text-gray-100 md:w-10/12 text-gray-600 text-xl leading-relaxed font-light ">Ullamco adipisicing ad enim dolore proident velit velit proident. Eiusmod et dolor aliquip do magna ut velit voluptate minim ad aliqua irure duis aliqua. Deserunt ad est dolor culpa eiusmod in elit irure.</p>
                        </div>
                        <div className="w-9/12 lg:w-8/12 xl:w-7/12">
                            <Button text={"Get Started"} />
                        </div>
                    </div>
                </div>
                <div className="m-auto hidden flex-grow-0 md:flex items-center justify-center">
                    <div>
                        {/* <h1 className="text-2xl dark:text-gray-400 text-gray-900">Learn Anywhere. Anytime.</h1>
                        <p>lorem*5</p> */}
                        {/* <iframe src="https://brilliant.org/site_media/version-V87e099514e4/images/homepage/hero-video.mp4"></iframe> */}
                        <video width="" height="" >
                            <source src="https://brilliant.org/site_media/version-V87e099514e4/images/homepage/hero-video.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="mt-4">

                    </div>
                </div>
            </section>
            <section className="w-full  mx-auto dark:bg-gray-600 justify-center flex items-center">

                <div className="py-20 w-9/12 m-auto">
                    <div className="flex m-auto font-light text-md flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-2  dark:text-gray-100 text-gray-600">
                        {/* <div className="flex justify-center items-center w-full bg-gray-300 dark:bg-gray-500 flex-auto h-40 rounded-md">
                            hello
                        </div> */}
                        <div className="flex justify-center items-center w-full italic bg-gray-300 dark:bg-gray-500 shadow-md flex-auto h-60 p-10 rounded-md">
                            <p className=" tracking-widest ">
                                "Aliqua in voluptate magna sit ad labore sint minim ullamco. Fugiat eu aliqua ipsum in irure anim cupidatat duis in."
                                <br />
                                -Sodere New
                            </p>
                        </div>
                        <div className="flex justify-center items-center w-full italic bg-gray-300 dark:bg-gray-500 shadow-md flex-auto h-60 p-10  rounded-md">
                            <p className=" tracking-widest  ">
                                "Fugiat eu aliqua ipsum in fugiat irure anim cupidatat duis in. Velit nostrud veniam labore ad ut mollit cupidatat pariatur qui ad consectetur fugiat."
                                <br />
                                -CNN
                            </p>
                        </div>
                        <div className="flex justify-center items-center w-full italic bg-gray-300 dark:bg-gray-500  shadow-md flex-auto  h-60 p-10 rounded-md">
                            <p className=" tracking-widest">
                                "Ullamco elit cupidatat et duis aliquip amet ullamco veniam exercitation pariatur ut non."
                                <br />

                                -EBC
                            </p>
                        </div>

                    </div>
                </div>
            </section>
            <section className="prose prose-lg dark:prose-invert w-9/12 m-auto h-full pb-10">
                <article className="m-auto font-light">

                    <h2>This is a test for prose</h2>

                    <h3>Heading 2 for Prose</h3>
                    <p> esse Adipisicing cillum laboris eu incididunt magna nulla minim. Nostrud adipisicing laboris est irure et. Ad ad ex sint sint reprehenderit nisi sit est tempor cupidatat. Ullamco laborum reprehenderit enim duis et officia. Do ex fugiat mollit esse sunt aute. Officia ullamco adipisicing aute labore. Deserunt mollit enim voluptate cillum pariatur aute occaecat ipsum cillum enim.consequat tempor do dolor est eu occaecat. Labore voluptate laboris est ea cupidatat cillum consequat nulla. Laborum ex eiusmod irure irure enim. Deserunt est non reprehenderit laborum sunt ex culpa magna cillum nisi sit.</p>
                    <p> esse consequat tempor do dolor est eu occaecat. Labore voluptate laboris est ea cupidatat cillum consequat nulla. Laborum ex eiusmod irure irure enim. Deserunt est non reprehenderit laborum sunt ex culpa magna cillum nisi sit.</p>
                    <p> Fugiat amet et Lorem excepteur commodo dolore commodo. Pariatur eiusmod quis reprehenderit commodo veniam officia ullamco ut fugiat ex laborum quis. Occaecat nisi velit veniam enim ex pariatur exercitation culpa consectetur ipsum. Adipisicing non proident laboris est incididunt duis velit non eu. Labore laboris occaecat adipisicing magna nisi. esse consequat tempor do dolor est eu occaecat. Labore voluptate laboris est ea cupidatat cillum consequat nulla. Laborum ex eiusmod irure irure enim. Deserunt est non reprehenderit laborum sunt ex culpa magna cillum nisi sit.</p>
                    <h3>Test 2 for Prose</h3>
                    <p>Laborum esse consequat tempor do dolor est eu occaecat. Labore voluptate laboris est ea cupidatat cillum consequat nulla. Laborum ex eiusmod irure irure enim. Deserunt est non reprehenderit laborum sunt ex culpa magna cillum nisi sit.</p>
                    <video width="" height="" >
                        <source src="https://brilliant.org/site_media/version-V87e099514e4/images/homepage/hero-video.mp4" type="video/mp4" />
                    </video>
                    <h3>Swweet 2 for Prose</h3>
                    <p>Consequat Dolor occaecat deserunt eu veniam sit in aliquip ullamco sint aliquip. Amet irure nostrud exercitation elit cupidatat pariatur nisi esse velit aliquip exercitation culpa. Et non ea mollit nostrud laboris id anim adipisicing enim. Aliqua anim occaecat aliqua proident eiusmod ex incididunt eiusmod sit ullamco laboris. Ex eiusmod ex exercitation fugiat ipsum consequat adipisicing ea amet nisi officia veniam. tempor do dolor est eu occaecat. Labore voluptate laboris est ea cupidatat cillum consequat nulla. Laborum ex eiusmod irure irure enim. Deserunt est non reprehenderit laborum sunt ex culpa magna cillum nisi sit.</p>
                    <h3>Spicy 2 for Prose</h3>
                    <p>Qui Sint veniam enim labore ut aliquip proident aliqua non cillum velit. Nostrud anim voluptate excepteur in commodo sint nulla voluptate. Elit qui consequat labore aute consectetur eiusmod occaecat nulla cillum voluptate.officia amet qui laboris labore commodo fugiat. Ea dolore pariatur laboris incididunt est elit anim. Ut eu aute irure adipisicing enim aute aliqua commodo labore.</p>
                </article>
            </section>
            <Footer />
        </div >
    )
};

export default Home;
