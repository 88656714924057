import { BeakerIcon, AcademicCapIcon, ChevronLeftIcon, XIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import NavBtn from "./NavLink";
import SideBar from "./SideBar";
import Toggle from "./ThemeToggle";

const Navbar = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    return (

        <header className="shadow-md m-none w-full sticky top-0 z-40 ">

            {/* NAVIGATION BAR */}

            <div className="flex items center justify-center m-auto w-9/12">
                <div className="flex flex-row justify-between items-center w-full h-16 text-gray-500 dark:text-gray-100">
                    <NavLink to="/" className="" exact>
                        <AcademicCapIcon className="h-12 w-12"></AcademicCapIcon>
                    </NavLink>
                    <div className="hidden lg:contents">
                        <div className="flex flex-row justify-start items-center h-full space-x-1 text-lg ">
                            {/* <NavLink to="/" exact className="focus:border-b-2 focus:border-blue-400 flex p-5 items-center justify-center lg:space-x-1 hover:cursor-pointer h-full w-12 sm:w-auto m-0 hover:border-b-2 border-blue-400 hover:bg-gray-800 group ">
                                <p className="h-full text-lg group-hover:transition-all duration-400 group-hover:text-blue-400 group-focus:text-blue-400">
                                    Home
                                </p>
                            </NavLink> */}
                            <NavBtn to={"/"} text="Home" />
                            <NavBtn to={"/courses"} text="Courses" />
                            <NavBtn to={"/pricing"} text="Pricing" />
                            <NavBtn to={"/contact"} text="Contact" />
                            <NavBtn to={"/blog"} text="Blog" />
                            <NavBtn to={"/about"} text="About" />
                        </div>
                    </div>

                    {/* LOGIN, SIGNUP, AND TOGGLE DARK MODE */}

                    <div className="hidden lg:contents">
                        <div className="mr-2 space-x-2 flex flex-row text-lg">
                            <NavLink to="/login" className=" flex items-center space-x-1 bg-slate-500 p-4 h-12 text-gray-50 text-center self-center font-medium rounded-md">
                                {" "}
                                <BeakerIcon className="h-4 w-4"></BeakerIcon>
                                <p> Login</p>
                            </NavLink>
                            <NavLink to="/signup" className=" flex items-center space-x-1 bg-blue-500 p-4 h-12 text-gray-50 text-center self-center font-medium rounded-md">
                                {" "}
                                <BeakerIcon className="h-4 w-4"></BeakerIcon>
                                <p> Signup</p>
                            </NavLink>
                            <Toggle></Toggle>
                        </div>
                    </div>


                    {/* NAVBAR TOGGLE FOR SMALL DEVICES */}

                    <div className="contents lg:hidden transition duration-300">
                        {navbarOpen ?
                            (
                                <div onClick={() => { setNavbarOpen(!navbarOpen) }} className="cursor-pointer hover:text-gray-600 p-2 w-12 h-12 flex items-center justify-center rounded-full ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="auto" viewBox="44.451 14.347 411.302 472.834" vector-effect="non-scaling-stroke" opacity=".97" fill="currentColor"><path vector-effect="non-scaling-stroke" d="M69.604 92.887c10.117-10.117 26.364-10.271 36.29-.345L439.163 425.81h0c9.926 9.926 9.771 26.174-.345 36.29l-8.222 8.222h0c-10.117 10.117-26.364 10.271-36.29.345L61.037 137.399h0c-9.926-9.926-9.771-26.174.345-36.29z" opacity="1" /><path vector-effect="non-scaling-stroke" d="M61.797 400.826c-10.346-10.346-10.677-26.788-.74-36.725L394.286 30.872h0c9.937-9.937 26.38-9.606 36.725.74l7.392 7.392h0c10.346 10.346 10.677 26.788.74 36.725L105.914 408.959h0c-9.937 9.937-26.38 9.606-36.725-.74z" opacity="1" /></svg>
                                </div>

                            ) : (<div className="cursor-pointer hover:text-gray-600 p-2 w-12 h-12 flex items-center justify-center rounded-full " onClick={() => { setNavbarOpen(!navbarOpen) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="auto" viewBox="39.404 51.789 422.788 406.764" fill="currentColor"><path vector-effect="non-scaling-stroke" d="M132.175 232.46c0-5.55 4.442-10.05 9.92-10.05h310.309 0c5.479 0 9.92 4.5 9.92 10.05v31.02h0c0 5.55-4.442 10.05-9.92 10.05H142.095h0c-5.479 0-9.92-4.5-9.92-10.05zM39.93 64.276c0-7.117 5.674-12.886 12.672-12.886h396.395 0c6.999 0 12.672 5.769 12.672 12.886v25.348h0c0 7.117-5.674 12.886-12.672 12.886H52.602h0c-6.999 0-12.672-5.769-12.672-12.886zm0 357.176c0-6.858 5.665-12.417 12.653-12.417h396.434 0c6.988 0 12.653 5.559 12.653 12.417v24.156h0c0 6.858-5.665 12.417-12.653 12.417H52.583h0c-6.988 0-12.653-5.559-12.653-12.417z" /></svg>
                            </div>)}
                    </div>
                </div>
            </div>

            {/* NAVIGATION BAR SMALL DEVICES */}

            <div className={navbarOpen ? "flex justify-center items-center  m-auto w-full text-center text-3xl bg-gray-100 dark:bg-gray-700" : "hidden"}>
                <div className="flex m-auto w-full flex-col justify-center items-center space-y-4 transition duration-300 ">
                    {/* <a className="hover:bg-gray-200 w-full p-4 text-3xl transition duration-300" href="#">Home</a>
                    <a className="hover:bg-gray-200 w-full p-4 text-3xl transition duration-300" href="#">Home</a>
                    <a className="hover:bg-gray-200 w-full p-4 text-3xl transition duration-300" href="#">Home</a>
                    <a className="hover:bg-gray-200 w-full p-4 text-3xl transition duration-300" href="#">Home</a>
                    <a className="hover:bg-gray-200 w-full p-4 text-3xl transition duration-300" href="#">Home</a> */}
                    <div className="flex justify-between w-full m-auto px-10 items-center mt-4">
                        <div className="mr-2 space-x-2 flex flex-row text-lg">
                            <NavLink to="/login" className=" flex items-center space-x-1 bg-slate-500 p-4 h-12 text-gray-50 text-center self-center font-medium rounded-md">
                                {" "}
                                <BeakerIcon className="h-4 w-4"></BeakerIcon>
                                <p> Login</p>
                            </NavLink>
                            <NavLink to="/signup" className=" flex items-center space-x-1 bg-blue-500 p-4 h-12 text-gray-50 text-center self-center font-medium rounded-md">
                                {" "}
                                <BeakerIcon className="h-4 w-4"></BeakerIcon>
                                <p> Signup</p>
                            </NavLink>

                        </div>
                        <Toggle></Toggle>
                    </div>
                    <NavLink onClick={() => { setNavbarOpen(!navbarOpen) }} to="/" exact className="m-auto focus:border-b-2 focus:border-blue-400 p-5  hover:cursor-pointer w-full hover:border-b-2 border-blue-400 hover:bg-gray-800 group ">
                        <p className="h-full group-hover:transition-all duration-400 group-hover:text-blue-400 group-focus:text-blue-400">
                            Home
                        </p>
                    </NavLink>
                    <NavLink onClick={() => { setNavbarOpen(!navbarOpen) }} to="/courses" exact className="m-auto focus:border-b-2 focus:border-blue-400 p-5  hover:cursor-pointer w-full hover:border-b-2 border-blue-400 hover:bg-gray-800 group ">
                        <p className="h-full group-hover:transition-all duration-400 group-hover:text-blue-400 group-focus:text-blue-400">
                            Courses
                        </p>
                    </NavLink>
                    <NavLink onClick={() => { setNavbarOpen(!navbarOpen) }} to="/princing" exact className="m-auto focus:border-b-2 focus:border-blue-400 p-5  hover:cursor-pointer w-full hover:border-b-2 border-blue-400 hover:bg-gray-800 group ">
                        <p className="h-full group-hover:transition-all duration-400 group-hover:text-blue-400 group-focus:text-blue-400">
                            Pricing
                        </p>
                    </NavLink>
                    <NavLink onClick={() => { setNavbarOpen(!navbarOpen) }} to="/contact" exact className="m-auto focus:border-b-2 focus:border-blue-400 p-5  hover:cursor-pointer w-full hover:border-b-2 border-blue-400 hover:bg-gray-800 group ">
                        <p className="h-full group-hover:transition-all duration-400 group-hover:text-blue-400 group-focus:text-blue-400">
                            Contact
                        </p>
                    </NavLink>
                    <NavLink onClick={() => { setNavbarOpen(!navbarOpen) }} to="/about" exact className="m-auto focus:border-b-2 focus:border-blue-400 p-5  hover:cursor-pointer w-full hover:border-b-2 border-blue-400 dark:hover:bg-gray-800 group border-b-2 border-transparent hover:border-solid">
                        <p className="h-full group-hover:transition-all duration-400 group-hover:text-blue-400 group-focus:text-blue-400">
                            About
                        </p>
                    </NavLink>

                </div>
            </div>
        </header>
    );
};

export default Navbar;
