const Contact = () => {
    return (
        <div className="bg-red-300">
            <div>
                <h1>
                    TITLE
                </h1>
            </div>
            <div>

                Quis consectetur aliquip veniam velit quis non consectetur nostrud aute dolore commodo. Ut cillum nulla anim cupidatat enim tempor laboris anim sit elit pariatur sint aliquip. Sint consequat nulla sint mollit id sunt labore non quis nostrud.
            </div>
        </div>
    )
}

export default Contact
