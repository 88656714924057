import React from 'react';
const Button = (props) => {

    return (
        <div className='m-auto w-full shadow-md'>
            <button {...props} className=" w-full rounded-md text-2xl p-4  bg-blue-500 text-gray-200 text-center font-medium " type="submit">{props.text}</button>
        </div>
    );
};

export default Button;